import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout, SEO, LastArticlesList } from 'components';
import { PageTitle, ArticleBody, Label } from 'ui';
import { PublishedDate } from 'components/ArticleCard';
import { formatDate } from 'utils';
import { Container, media, Stack } from '@tymate/margaret';
import mediumZoom from 'medium-zoom';

const ArticleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.spacing(4)};
  margin-top: ${({ theme }) => theme.spacing(2)};

  ${media.desktop`
    grid-template-columns: 1fr 384px;
  `}
`;

const Article = ({ data }) => {
  useEffect(() => {
    mediumZoom(document.querySelectorAll('#body img'));
  }, []);

  return (
    <Layout>
      <SEO
        title={data?.datoCmsArticle?.title}
        description={data?.datCmsArticle?.description}
      />
      <Container variant="main">
        <ArticleGrid>
          <div>
            <Label>{data?.datoCmsArticle?.skill?.name}</Label>
            <PageTitle>{data?.datoCmsArticle?.title}</PageTitle>
            <ArticleBody
              id="body"
              dangerouslySetInnerHTML={{
                __html:
                  data?.datoCmsArticle?.contentNode?.childMarkdownRemark?.html,
              }}
            />
            <Stack marginTop={2} direction="column">
              <PublishedDate>
                {data?.datoCmsArticle?.author?.name}
              </PublishedDate>
              <PublishedDate>
                {formatDate(
                  data?.datoCmsArticle?.meta?.publishedAt,
                  'd MMMM yyyy',
                )}
              </PublishedDate>
            </Stack>
          </div>
          <div>
            <LastArticlesList articles={data?.allDatoCmsArticle?.edges} />
          </div>
        </ArticleGrid>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $limit: Int) {
    datoCmsArticle(slug: { eq: $slug }) {
      title
      slug
      description
      author {
        name
      }
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      meta {
        publishedAt
      }
      skill {
        id
        name
      }
    }
    allDatoCmsArticle(
      limit: $limit
      filter: { slug: { ne: $slug } }
      sort: { order: ASC, fields: meta___publishedAt }
    ) {
      edges {
        node {
          slug
          title
          description
          meta {
            publishedAt
          }
        }
      }
    }
  }
`;

export default Article;
